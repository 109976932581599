import React from "react";
import "./Contacts.scss";
import { useTranslation } from "react-i18next";

const Contacts = () => {
  const { t } = useTranslation()

  return (
    <section className="cont">
      <div className="container">
        <h2 className="cont__title">{t("cont")}</h2>
        <div className="cont__wrapper">
          <div className="cont__left">
            <div className="cont__info">
              <div className="cont__content">
                <p className="cont__cont">{t("cont")} </p>
                <a href="" id="cont" className="cont__num">
                  {" "}
                  +998 (99) 802 22 00{" "}
                </a>
                <a href="" className="cont__num">
                  {" "}
                  +998 (99) 008 08 80{" "}
                </a>
              </div>
              <div className="cont__content">
                <p className="cont__cont">{t("off")} </p>
                <a href="" className="cont__num">
                  +998 (55) 501 51 15
                </a>
                <a href="" className="cont__num">
                  {" "}
                  +998 (55) 501 39 00
                </a>
              </div>
            </div>
            <div className="footer__soc">
              <h2 className="footer__title">
                {t("soc")}
              </h2>
              <div className="gg">
                <div className="footer__inst">
                  <a href="https://www.instagram.com/nodir_pulatov_2200?igsh=cnppajlqbGZuc2o5">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 206 206"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M103.281 205.199H102.832C46.5951 205.199 0.841919 159.432 0.841919 103.179V102.729C0.841919 46.4759 46.5951 0.709229 102.832 0.709229H103.281C159.518 0.709229 205.271 46.4759 205.271 102.729V103.179C205.271 159.432 159.518 205.199 103.281 205.199ZM102.832 7.63099C50.4079 7.63099 7.76164 50.2898 7.76164 102.729V103.179C7.76164 155.618 50.4079 198.277 102.832 198.277H103.281C155.705 198.277 198.352 155.618 198.352 103.179V102.729C198.352 50.2898 155.705 7.63099 103.281 7.63099H102.832Z"
                        fill="url(#paint0_linear_1_500)"
                      />
                      <path
                        d="M133.618 44.3649H72.5025C55.6184 44.3649 41.8828 58.1046 41.8828 74.9937V130.922C41.8828 147.811 55.6184 161.55 72.5025 161.55H133.618C150.502 161.55 164.237 147.811 164.237 130.922V74.9937C164.237 58.1046 150.502 44.3649 133.618 44.3649ZM52.6845 74.9937C52.6845 64.0642 61.5763 55.1697 72.5025 55.1697H133.618C144.544 55.1697 153.436 64.0642 153.436 74.9937V130.922C153.436 141.851 144.544 150.745 133.618 150.745H72.5025C61.5763 150.745 52.6845 141.851 52.6845 130.922V74.9937Z"
                        fill="url(#paint1_linear_1_500)"
                      />
                      <path
                        d="M103.06 131.441C118.761 131.441 131.542 118.663 131.542 102.951C131.542 87.2383 118.768 74.4607 103.06 74.4607C87.3524 74.4607 74.5786 87.2383 74.5786 102.951C74.5786 118.663 87.3524 131.441 103.06 131.441ZM103.06 85.2725C112.81 85.2725 120.74 93.2048 120.74 102.958C120.74 112.71 112.81 120.643 103.06 120.643C93.3103 120.643 85.3803 112.71 85.3803 102.958C85.3803 93.2048 93.3103 85.2725 103.06 85.2725Z"
                        fill="url(#paint2_linear_1_500)"
                      />
                      <path
                        d="M134.178 79.0637C138.406 79.0637 141.852 75.6236 141.852 71.3874C141.852 67.1513 138.413 63.7112 134.178 63.7112C129.943 63.7112 126.504 67.1513 126.504 71.3874C126.504 75.6236 129.943 79.0637 134.178 79.0637Z"
                        fill="url(#paint3_linear_1_500)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1_500"
                          x1="30.749"
                          y1="175.283"
                          x2="175.407"
                          y2="30.6676"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FAAD4F" />
                          <stop offset="0.35" stop-color="#DD2A7B" />
                          <stop offset="0.62" stop-color="#9537B0" />
                          <stop offset="1" stop-color="#515BD4" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_1_500"
                          x1="52.1585"
                          y1="153.867"
                          x2="153.985"
                          y2="52.078"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FAAD4F" />
                          <stop offset="0.35" stop-color="#DD2A7B" />
                          <stop offset="0.62" stop-color="#9537B0" />
                          <stop offset="1" stop-color="#515BD4" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_1_500"
                          x1="82.9307"
                          y1="123.093"
                          x2="123.195"
                          y2="82.834"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FAAD4F" />
                          <stop offset="0.35" stop-color="#DD2A7B" />
                          <stop offset="0.62" stop-color="#9537B0" />
                          <stop offset="1" stop-color="#515BD4" />
                        </linearGradient>
                        <linearGradient
                          id="paint3_linear_1_500"
                          x1="128.753"
                          y1="76.8141"
                          x2="139.606"
                          y2="65.964"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#FAAD4F" />
                          <stop offset="0.35" stop-color="#DD2A7B" />
                          <stop offset="0.62" stop-color="#9537B0" />
                          <stop offset="1" stop-color="#515BD4" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </a>
                </div>
                <div className="footer__tg">
                  <a href="https://t.me/nodir5115">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 205 206"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M102.484 205.199H102.035C45.7979 205.199 0.0447998 159.432 0.0447998 103.179V102.729C0.0447998 46.4759 45.7979 0.709229 102.035 0.709229H102.484C158.721 0.709229 204.474 46.4759 204.474 102.729V103.179C204.474 159.432 158.721 205.199 102.484 205.199ZM102.035 7.63099C49.6107 7.63099 6.96452 50.2898 6.96452 102.729V103.179C6.96452 155.618 49.6107 198.277 102.035 198.277H102.484C154.908 198.277 197.554 155.618 197.554 103.179V102.729C197.554 50.2898 154.908 7.63099 102.484 7.63099H102.035Z"
                        fill="#00B0F2"
                      />
                      <path
                        d="M40.9125 99.5867C41.1893 99.4483 41.4662 99.3168 41.7361 99.1922C46.4277 97.0188 51.1815 94.9838 55.9285 92.9488C56.1845 92.9488 56.6134 92.6511 56.8556 92.5542C57.2223 92.395 57.5891 92.2428 57.9559 92.0836C58.6617 91.779 59.3676 91.4813 60.0664 91.1768C61.4781 90.5746 62.8827 89.9724 64.2943 89.3702C67.1106 88.1658 69.9269 86.9614 72.7433 85.7501C78.3759 83.3414 84.0156 80.9257 89.6483 78.5169C95.2809 76.1082 100.92 73.6924 106.553 71.2836C112.186 68.8749 117.825 66.4592 123.458 64.0504C129.091 61.6417 134.73 59.2259 140.363 56.8171C141.615 56.2772 142.972 55.4744 144.314 55.239C145.442 55.0383 146.542 54.6507 147.677 54.4361C149.829 54.0277 152.202 53.8616 154.265 54.7545C154.977 55.0659 155.635 55.502 156.181 56.0488C158.797 58.6376 158.43 62.8875 157.877 66.5284C154.022 91.9036 150.168 117.286 146.307 142.661C145.781 146.142 145.061 149.963 142.314 152.164C139.989 154.026 136.682 154.234 133.81 153.445C130.938 152.649 128.406 150.981 125.921 149.34C115.618 142.516 105.308 135.691 95.0041 128.866C92.5545 127.246 89.8282 125.128 89.8559 122.186C89.8697 120.414 90.9284 118.836 92.0078 117.431C100.962 105.747 113.881 97.7178 123.493 86.5738C124.849 85.0025 125.915 82.1647 124.053 81.2579C122.946 80.718 121.673 81.4518 120.662 82.1509C107.958 90.9761 95.2602 99.8082 82.5555 108.633C78.4106 111.513 74.0651 114.475 69.069 115.181C64.5989 115.818 60.1011 114.572 55.7762 113.299C52.1503 112.233 48.5312 111.139 44.926 110.011C43.0092 109.416 41.0302 108.772 39.5494 107.422C38.0685 106.072 37.2175 103.802 38.1102 102.002C38.6707 100.874 39.7571 100.161 40.8988 99.5798L40.9125 99.5867Z"
                        fill="#00B0F2"
                      />
                    </svg>
                  </a>
                </div>
                <div className="footer__whats">
                  <a target="_blank" href="https://www.facebook.com/nodir.pulatov.16?mibextid=LQQJ4d">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_5127_22839)">
                        <path
                          d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
                          fill="#3C5A9A"
                        />
                        <path
                          d="M39.7534 9.20801H33.1077C29.1639 9.20801 24.7773 10.8667 24.7773 16.5834C24.7965 18.5753 24.7773 20.483 24.7773 22.63H20.2148V29.8902H24.9185V50.7911H33.5616V29.7523H39.2664L39.7826 22.6096H33.4127C33.4127 22.6096 33.427 19.4323 33.4127 18.5095C33.4127 16.2504 35.7634 16.3798 35.9048 16.3798C37.0234 16.3798 39.1984 16.3831 39.7567 16.3798V9.20801H39.7534Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_5127_22839">
                          <rect width="60" height="60" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cont__right">
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3112.5330226071737!2d69.35113607605732!3d41.3410389713058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDIwJzI3LjciTiA2OcKwMjEnMTMuNCJF!5e1!3m2!1sru!2s!4v1730454698227!5m2!1sru!2s" style={{ width: 600, height: 450, border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
